export const state = () => ({
  isUpdate: false, // 자산수집 중 여부 true/false
  tlccoBfInqrYm: '', // 통신사 이전화면 조회년월
});

export const mutations = {
  // 자산수집 중 여부 변경
  setIsUpdate(state, payload) {
    state.isUpdate = payload;
  },
  // 통신사 이전화면 조회년월 유지
  setTlccoBfInqrYm(state, payload) {
    state.tlccoBfInqrYm = payload;
  },
};

export const getters = {
  // 자산수집 중 여부 확인
  getIsUpdate(state) {
    return state.isUpdate;
  },
  // 통신사 이전화면 조회년월 유지
  getTlccoBfInqrYm(state) {
    return state.tlccoBfInqrYm;
  },
};
