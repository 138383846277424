import CommonUtil from '@/utils/common-util.js';
/**
 * Adobe 초기 설정 스크립트
 * (기존에 static/js/adobe/data-layer.js 에 있던것을 plugin으로 이관)
 */

// 넷스루 솔루션 적용
if (CommonUtil.isProd()) {
  const installScriptEl = document.createElement('script');
  installScriptEl.src = 'https://ntm.samsungcard.com/static/10001/install.js';
  installScriptEl.defer = true;
  document.body.appendChild(installScriptEl);
} else if (CommonUtil.isTest()) {
  const installScriptEl = document.createElement('script');
  installScriptEl.src = 'https://ntm.samsungcard.biz/static/10001/install.js';
  installScriptEl.defer = true;
  document.body.appendChild(installScriptEl);
}
