export const state = () => ({
  enabled: false, // 전체 모니터링 사용 여부
  sampleRate: 0, // 오류 모니터링 샘플링 비율
  tracesSampleRate: 0, // 트랜잭션 모니터링 샘플링 비율
  replaysSessionSampleRate: 0, // 세션 리플레이 샘플링 비율
  replaysOnErrorSampleRate: 0, // 오류 리플레이 샘플링 비율

  reInqrPrdMtc: 0, // 정책 조회 주기
  lastInqrDateTime: 0, // 정책 마지막 조회 시간
});

export const getters = {
  // 전체 모니터링 사용 여부
  getEnabled(state) {
    return state.enabled;
  },

  // 오류 모니터링 샘플링 비율
  getSampleRate(state) {
    return state.sampleRate;
  },

  // 트랜잭션 모니터링 샘플링 비율
  getTracesSampleRate(state) {
    return state.tracesSampleRate;
  },

  // 세션 리플레이 샘플링 비율
  getReplaysSessionSampleRate(state) {
    return state.replaysSessionSampleRate;
  },

  // 오류 리플레이 샘플링 비율
  getReplaysOnErrorSampleRate(state) {
    return state.replaysOnErrorSampleRate;
  },

  // 정책 조회 주기
  getReInqrPrdMtc(state) {
    return state.reInqrPrdMtc;
  },
  // 정책 마지막 조회 시간
  getLastInqrDateTime(state) {
    return state.lastInqrDateTime;
  },
};

export const mutations = {
  // 전체 모니터링 사용 여부
  setEnabled(state, payload) {
    state.enabled = payload;
  },

  // 오류 모니터링 샘플링 비율
  setSampleRate(state, payload) {
    state.sampleRate = payload;
  },

  // 트랜잭션 모니터링 샘플링 비율
  setTracesSampleRate(state, payload) {
    state.tracesSampleRate = payload;
  },

  // 세션 리플레이 샘플링 비율
  setReplaysSessionSampleRate(state, payload) {
    state.replaysSessionSampleRate = payload;
  },

  // 오류 리플레이 샘플링 비율
  setReplaysOnErrorSampleRate(state, payload) {
    state.replaysOnErrorSampleRate = payload;
  },

  // 정책 조회 주기
  setReInqrPrdMtc(state, payload) {
    state.reInqrPrdMtc = payload;
  },
  // 정책 마지막 조회 시간
  setLastInqrDateTime(state, payload) {
    state.lastInqrDateTime = payload;
  },
};
