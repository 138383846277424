import Log from '@/utils/log';

/**
 * 클릭코드 태깅
 */
const clickTagging = clickCode => {
  try {
    if (clickCode) {
      Log.log('[공통-adobeUtil] clickTagging clickCode: ', clickCode);

      // nethru
      if (window?.Ntm) {
        window.Ntm?.Event?.fireUserDefined('ntm_excute', { nclick: clickCode });
      }
    }
  } catch (error) {
    Log.error('[공통-adobeUtil] clickTaggingError: ', error.message);
  }
};

/**
 * 개인화배너 노출 태깅
 */
const psnzBnnrTagging = (mnmPsnzCntsIdList = []) => {
  try {
    // nethru
    if (mnmPsnzCntsIdList.length > 0) {
      Log.log('[공통-adobeUtil] psnzBnnrTagging mnmPsnzCntsIdList: ', mnmPsnzCntsIdList);
      const percontentsid = mnmPsnzCntsIdList.join('|');
      if (window?.Ntm) {
        window.Ntm?.Event?.fireUserDefined('ntm_excute', { depth5: percontentsid });
      }
    }
  } catch (error) {
    Log.error('[공통-adobeUtil] psnzBnnrTaggingError: ', error.message);
  }
};

export default {
  clickTagging,
  psnzBnnrTagging,
};
